<template>
	<v-sheet color="transparent">
		<Question v-for="id in visibleQuestions" :key="id" :data="false" :id="id" @updated="(v) => updateValue(id, v)"></Question>
	</v-sheet>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	import Vue from "vue";
	import Vuex from "vuex";
	import Question	from "@/components/survey/render/Question"
	export default {
		name: "Preview", 
		props: {
			questions: {},
		},
		data: () => {
			return {
				values: {

				} 
			}
		},
		components: {
			Question
		},
		computed: {
			...Vuex.mapState({
				questionObjects: state => state.questions
			}),
			visibleQuestions(){
				const self = this;
				return self.questions.filter( a => {
					return self.visibilityTest( a )
				})
			}, 
		},
		methods: {
			updateValue(id, value ){
				Vue.set(this.values, id, value)
			}, 
			visibilityTest( id ){
				var question = this.questionObjects[id]
				var condition = question.visibleIf
				
				if( !condition ){
					return true;
				}
				else{
					var visible = false;
					var value = this.values[condition.question]
					if( condition.operator == "in" ){
						visible = condition.value.indexOf( value ) > -1 
					}
					else{
						var test = `'${value}' ${condition.operator} '${condition.value}'`;
						visible = eval(test);  
					}
					return visible;
				}
			}
		}
		// created(){
			
		// }

	}
// </script>"